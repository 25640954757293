const allowlist = [
  '0x3711645dc16328ae49291823179b1efff1e1072f0cac48e5178811cfe3145a50',
  '0x825ff57ff0336e2d84109bac6d7f70b005bc1a3bbfa49bc76a6d65bbc1994eb4',
  '0x81dc55dafaa1aeb68afcdbc86f1e8bcb448cb91558a24eb973833bc1817e64f0',
  '0xa3d85e62a278b8a03f5d5726fbb8c10784994245e9d22420fb7a8f2fbf6875e0',
  '0xf8162b4e79f0f1c78d86e5f5ce4917eacaf0d73d05f73981cd01c79214df534e',
  '0x0879bc18a9ad5ba75b9bc2dc92e8f9eebb28b83cc21cbd82945a8eae56fc9059',
  '0x02d6ae28dcda16e0b15b8929a9c7233a85135981bd46c3482af2e752ae29d162',
  '0xb3df68b8e243661a941b7b0887a1fe64b86619469e3e7fe0280d4435435a5e7b',
  '0x749a3b160cf63dabf59e8ddad395d2c4ca198a5330c250b4c8a64d24b61bf892',
  '0xca5587605b9bcf42311435d25d38d93d3ef1b4f2d0896d9da30169c2b8326683',
  '0x7de7b063412e5656fb6961d1d7f77cc4855c284cebcf73a1778898297d56aba1',
  '0xa7db291cb1db7d1a849cf48de52643a22c19f28cd141acc0e9de05f5e1dae0f4',
  '0x1bdbf6f38584d80e5b2f7e6bdee3a99c83d7da23a57bbe126805e04bc7b6c7fb',
  '0xaed2f4f2993b5f66c614310b8edfbd1be3eb67f8d3b6eb08a46f7b29ad6e0f4a',
  '0xdfdc5695d0d0d9c40f6f3de0813e2d081de5d5c51c17b833211d8171c32ee8b3',
  '0x531329463a7049c10126487ce8090dd6796fdc3df2ee05c3aa9c7df178370294',
  '0xfe011090995fb7afe2fdda094c458b100c81cb907d2578d0288b2582116c2ddc',
  '0x8ee92e19f312a5eecc80a654110db6f3c159b161d9042a2b89f58beb6b1b0945',
  '0x48f4dd506fc4067e633f306bb528e083e8f8336e31cc33fc146430483255e915',
  '0x15e5fc2de17ce308c37ee825d0b02001e09fdd15ce79eae7c89d493341bdce71',
  '0xf81d94677a5197cf837097b4170ec515c3a94ed4d120ecb0cbb51286e8a9204e',
  '0x348e743a08347c4d33a23851ebfed92e7b8143caa1b8b11894c03c7bd1f7de13',
  '0x068ed069cf98e3e5f72c323b8052202e51a533fdcd57508a1da58e3cdf41f795',
  '0xc050317043c3e16d8ed5f7d6746c3a2189f10dd2a7823f56002edcf1a476ccfe',
  '0x5fb76b46e00727f7d0a9fa347ccaabc36ffd1a175fa138bf905a99e229628f61',
  '0xe5d3a3dc9bfc993e6e295480dd4589757dd5ea5189846cb989037b10e78169c6',
  '0x1787e5f3bc5b4f9c1048bcd6abb353b3a1236c6e3347003902de387899e2bda2',
  '0xb4d6e2d1d0d3a216e13ba656f3f543378284a787dac607a9ac2eb75602f2853a',
  '0x70dc35544c8adbecbee6bbdf836d1977fd92e2af8fd774a8f306627e8c447fc8',
  '0x3fb439e81c913077906ce2d4077d8e3015967c7752ca1b232d9187a8ceeb162d',
];

export default allowlist;
